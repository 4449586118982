import React, { useState, useContext } from 'react';
import { AdvisorContext } from 'containers/advisor';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import './styles.scss';

const CheckIns = ({ investor }) => {
  const { investorProvider, prospectProvider } = useContext(AdvisorContext);
  const [isLoadingToggle, setIsLoadingToggle] = useState(false);

  const isProspect = window.location.pathname.includes('prospects');

  const onSubmit = async () => {
    setIsLoadingToggle(true);

    const updatedInvestor = {
      id: investor.id,
      checkins_enabled: !investor.checkins_enabled
    };

    const provider = isProspect ? prospectProvider : investorProvider;

    await provider.update(updatedInvestor).then(result => {
      provider.get(result.data.id).finally(() => {
        setIsLoadingToggle(false);
        toast.success(
          `Check-ins have been ${updatedInvestor.checkins_enabled ? 'enabled' : 'disabled'} for ${
            investor.full_name
          }`
        );
      });
    });
  };

  return (
    <div id="check-ins">
      <div className="card main-container">
        {investor && (
          <div className="togggle-container">
            <Toggle
              disabled={isLoadingToggle}
              defaultChecked={investor.checkins_enabled}
              className="toggle-primary"
              id="toggle-benchmark-unit"
              onChange={onSubmit}
            />
            <span className="toggle-slider" />
            <span className="toggle-label">Check-Ins</span>
          </div>
        )}
      </div>
    </div>
  );
};

CheckIns.propTypes = {
  investor: PropTypes.object.isRequired
};

// Map only the investor state to props
const mapStateToProps = state => ({
  investor: state.investors.view
});

export default connect(mapStateToProps)(CheckIns);
