import { RequestAction } from 'components/advisor/section-locked';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import '../styles.scss';

const EmailCard = ({ title, subject, onModify }) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const canModify = title !== 'Check-in Email' || company.checkins_available;

  // FIXME:
  // This is a temporary patch to disable the check-ins feature.
  // Remove this line when the feature is ready.
  if (!canModify) return null;

  return (
    <div className="button-container card">
      <div className="card-title">
        <h5>{title}</h5>
      </div>
      <div className="email-card-content">
        <p>
          <b>Subject</b>: {subject}
        </p>
        {canModify ? (
          <button
            type="button"
            className="toggle-email-template-button btn btn-outline-secondary"
            onClick={onModify}
          >
            Modify
          </button>
        ) : (
          <RequestAction className=" btn btn-primary " name="Upgrade plan" />
        )}
      </div>
    </div>
  );
};

EmailCard.propTypes = {
  title: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  onModify: PropTypes.func.isRequired
};

export default EmailCard;
