import cn from 'classnames';
import SelectClassification from 'components/advisor/models/positions-formset/select-classification';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import Disclosure from 'components/disclosure';
import { AllowNewItemsAutosuggest, VerboseErrorInput as Input } from 'components/form';
import SpinnerLoader from 'components/performance-spinner';
import WarningIcon from 'components/svg-icons/warning-icon';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { POST_CREATE_STEP, SECURITY_TARGET_TYPE } from '../../constants';
import './styles.scss';

const validate = values => {
  const errors = { ticker_name: '', ticker: '', type: '', subtype: '', sector: '' };

  errors.ticker_name = errors.ticker_name || validation.required(values.ticker_name);
  errors.ticker = errors.ticker || validation.required(values.ticker);
  errors.type = errors.type || validation.required(values.type);
  errors.subtype = errors.subtype || validation.required(values.subtype);
  errors.sector = errors.sector || validation.required(values.sector);

  return errors;
};

const validateChangeFields = values => {
  const dirty = {};

  dirty.ticker_name = dirty.ticker_name || values.ticker_name.dirty;
  dirty.ticker = dirty.ticker || values.ticker.dirty;
  dirty.type = dirty.type || values.type.dirty;
  dirty.sector = dirty.sector || values.sector.dirty;
  dirty.cusip = dirty.cusip || values.cusip.dirty;
  dirty.proxy = dirty.proxy || values.proxy.dirty;

  return dirty;
};

const SecurityOverview = ({
  bulkCreateSecurity,
  classifications,
  errors,
  fields,
  isVisible,
  isBulk,
  handleSubmit,
  initializeForm,
  marketStore,
  dirty,
  params,
  row,
  security,
  updateStep
}) => {
  const { customSecurityProvider, routerActions, marketProvider, user } =
    useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [proxyName, setProxyName] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const debounceDelay = 500;
  const marketSecurities = marketStore.securities.search || [];
  const securityID = params?.id;
  const securitySource = securityID ? security : fields;
  const customSecuritiesPrefix = user?.advisor?.company?.custom_securities_prefix || '';

  const initializeFormFields = () => {
    const values = {
      ticker_name: security.ticker_name || '',
      ticker: security.ticker || '',
      cusip: security.cusip || '',
      type: security.type || '',
      subtype: security.subtype || '',
      sector: security.sector || '',
      proxy: security.proxy || ''
    };
    return initializeForm(values);
  };

  useEffect(() => {
    initializeFormFields();
  }, [isVisible]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      customSecurityProvider.clear();
    };
  }, []);

  useEffect(() => {
    if (security) initializeFormFields();
    if (_.isEmpty(security)) {
      initializeFormFields();
      if (securityID) {
        toast.error('The requested security does not exist');
        routerActions.push('/advisor/securities');
      }
    }
  }, [JSON.stringify(security)]);

  useEffect(() => {
    const fieldsToWatch = ['ticker_name', 'ticker', 'cusip'];

    const shouldClearErrors = fieldsToWatch.some(
      field => validationErrors[field] && fields[field].value
    );

    if (shouldClearErrors)
      setValidationErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        fieldsToWatch.forEach(field => {
          if (fields[field].value) delete newErrors[field];
        });
        return newErrors;
      });
  }, [fields.ticker_name.value, fields.ticker.value, fields.cusip.value]);

  const handleValidationError = errors => {
    const errorFields = ['ticker_name', 'ticker', 'cusip'];
    const capturedErrors = {};

    errorFields.forEach(field => {
      if (errors[field]) capturedErrors[field] = errors[field];
    });

    return capturedErrors;
  };

  const handleSave = useCallback(
    _.debounce(values => {
      setLoadingRequest(true);
      const cleanedValues = _.omitBy(
        values,
        (value, key) => key === 'proxy' && value == null // if proxy is null the request omit the proxy value
      );
      const updatedSecurity = { ...cleanedValues };
      if (securityID)
        return customSecurityProvider
          .edit(security.id, updatedSecurity)
          .then(response => {
            if (response.error)
              toast.error('An error occurred while saving the security information.');
            else toast.success('🎊 The security information was saved successfully.');
          })
          .finally(() => {
            setLoadingRequest(false);
          });
      if (isBulk) return bulkCreateSecurity(row, updatedSecurity);
      return customSecurityProvider
        .create(updatedSecurity)
        .then(response => {
          if (response.error) {
            const responseValidationErrors = handleValidationError(response.error.errors);
            if (Object.keys(responseValidationErrors).length > 0)
              setValidationErrors(responseValidationErrors);
            toast.error('There was an error creating the custom security.');
          } else {
            customSecurityProvider.saveSecurity(response.data);
            toast.success('🎊 The custom security was created successfully.');
            updateStep(POST_CREATE_STEP);
          }
        })
        .finally(() => setLoadingRequest(false));
    }, debounceDelay),
    [customSecurityProvider]
  );

  const securitySearch = useCallback(
    _.debounce(value => {
      if (value) marketProvider.es.securitySearch(value);
    }, debounceDelay),
    [marketProvider]
  );

  const handleCancel = () => {
    if (securityID) return initializeFormFields();
    return routerActions.push('/advisor/securities');
  };

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleSave)}>
      <section id="security-overview-container" className="risk-analysis">
        <div id="company-profile" className="risk-score-result-container">
          <div
            className={cn('content card', {
              'card-security-detail--overview': securityID
            })}
          >
            <div className="row row-drift-exceptions">
              <div className="col">
                <span>Assets / Security name</span>
                <div>
                  <Input
                    type="text"
                    className="form-control"
                    {...fields.ticker_name}
                    error={fields.ticker_name.error || validationErrors.ticker_name}
                  />
                </div>
              </div>
              <div className="col">
                <span>Symbol</span>
                <div className="symbol-input__container">
                  {!securityID && customSecuritiesPrefix && (
                    <span className="symbol-input__prefix">{customSecuritiesPrefix}</span>
                  )}
                  <Input
                    type="text"
                    fieldsetClassName="symbol-input__fieldset"
                    className={cn('form-control', {
                      'symbol-input__text': !securityID
                    })}
                    disabled={!!securityID}
                    {...fields.ticker}
                    value={fields.ticker.value?.toUpperCase()}
                    onChange={event => {
                      const uppercasedValue = event.target.value.toUpperCase();
                      fields.ticker.onChange(uppercasedValue);
                    }}
                  />
                </div>
                {fields.ticker.touched && fields.ticker.dirty && (
                  <span className="text-danger error">
                    {fields.ticker.error || validationErrors.ticker}
                  </span>
                )}
              </div>
              <div className="col">
                <span>Cusip</span> <span className="optional-label"> - Optional</span>
                <Input
                  type="text"
                  className="form-control"
                  {...fields.cusip}
                  error={fields.cusip.error || validationErrors.cusip}
                />
              </div>
            </div>
            <div>
              <div className="row row-drift-exceptions">
                <div className="col">
                  <span>Assets class</span>
                  <SelectClassification
                    position={securitySource}
                    classifications={classifications}
                    classificationType="type"
                    type={SECURITY_TARGET_TYPE}
                    valueSecurity={fields.type}
                    {...fields.type}
                  />
                </div>
                <div className="col">
                  <span>Subtype</span>
                  <SelectClassification
                    position={securitySource}
                    classifications={classifications}
                    classificationType="subtype"
                    type={SECURITY_TARGET_TYPE}
                    valueSecurity={fields.subtype}
                    {...fields.subtype}
                  />
                </div>
                <div className="col">
                  <span>Segment</span>
                  <SelectClassification
                    position={securitySource}
                    classifications={classifications}
                    classificationType="sector"
                    type={SECURITY_TARGET_TYPE}
                    valueSecurity={fields.sector}
                    {...fields.sector}
                  />
                </div>
              </div>
              <div className="row row-drift-exceptions">
                <div className="col">
                  <div
                    className={cn('security-input-container', {
                      'security-input-container--create': !securityID
                    })}
                  >
                    <span className="span-label">
                      Benchmark <span className="optional-label"> - Optional</span>
                    </span>
                    <AllowNewItemsAutosuggest
                      field={fields.proxy}
                      suggestions={marketSecurities}
                      className="suggestion__form"
                      getSuggestionValue={suggestion => suggestion.ticker}
                      renderSuggestion={suggestion => (
                        <span>
                          {suggestion.prism_overall ? (
                            <ScoreBubble score={suggestion.prism_overall} />
                          ) : (
                            <span className="no-score-warning">
                              <WarningIcon className="warning-icon" title="Score not available" />
                            </span>
                          )}{' '}
                          {suggestion.ticker}: {suggestion.ticker_name}
                        </span>
                      )}
                      onSuggestionSelected={(_, { suggestion }) => {
                        fields.proxy.onChange(suggestion.ticker);
                        setProxyName(suggestion.ticker_name);
                      }}
                      onSuggestionsFetchRequested={({ value }) => {
                        fields.proxy.disabled = false;
                        securitySearch(value);
                      }}
                      inputProps={{
                        placeholder: 'Type Proxy',
                        value: fields.proxy.value || '', // should always be string
                        onChange: (event, { newValue }) => {
                          // update input value
                          fields.proxy.onChange(newValue.toUpperCase());
                          setProxyName('');
                        }
                      }}
                      theme={{
                        container: 'dropdown react-autosuggest__container',
                        containerOpen: 'open react-autosuggest__container--open',
                        input: 'form-control react-autosuggest__input',
                        suggestionsContainer:
                          'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container',
                        suggestion: 'dropdown-item react-autosuggest__suggestion',
                        suggestionFocused: 'react-autosuggest__suggestion--focused'
                      }}
                    />
                    {!_.isEmpty(fields.proxy.value) && (
                      <span className="suggestion-ticker_name">{proxyName}</span>
                    )}
                  </div>
                </div>
                <div className="col security-col-bulk ">
                  {isBulk && (
                    <button
                      type="button"
                      className="btn btn-primary security-btn security-btn-bulk"
                      onClick={handleSubmit(handleSave)}
                      disabled={
                        (errors && !_.isEmpty(errors)) || loadingRequest || (!!securityID && !dirty)
                      }
                    >
                      Create
                    </button>
                  )}
                </div>
              </div>
              <div className="row security-btn-container">
                {!isBulk && (
                  <div className="col-4">
                    <button
                      type="button"
                      className="btn btn-secondary security-btn"
                      onClick={() => handleCancel()}
                      disabled={loadingRequest || (!!securityID && !dirty)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary security-btn security-btn-save"
                      disabled={
                        (errors && !_.isEmpty(errors)) || loadingRequest || (!!securityID && !dirty)
                      }
                    >
                      {securityID ? 'Save' : 'Create'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {!isBulk && <Disclosure />}
    </form>
  );
};

SecurityOverview.defaultProps = {
  bulkCreateSecurity: null,
  isBulk: false,
  isVisible: true,
  updateStep: null,
  row: {}
};

SecurityOverview.propTypes = {
  classifications: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dirty: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  initializeForm: PropTypes.func.isRequired,
  marketProvider: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  row: PropTypes.object,
  security: PropTypes.object.isRequired,
  updateStep: PropTypes.func,
  bulkCreateSecurity: PropTypes.func,
  isBulk: PropTypes.bool,
  isVisible: PropTypes.bool
};

const mapStateToProps = state => ({
  classifications: state.market.classifications || { type: [], subtype: [], sector: [] },
  security: state.customSecurity.currentSecurity,
  marketStore: state.market,
  fields: ['ticker_name', 'ticker', 'cusip', 'type', 'subtype', 'sector', 'proxy'],
  initialValues: {
    ticker_name: '',
    ticker: '',
    cusip: '',
    type: '',
    subtype: '',
    sector: '',
    proxy: ''
  }
});

const SecurityOverviewWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true,
  validate,
  validateChangeFields
})(SecurityOverview);

const SecurityOverviewWithOutRout = withRouter(SecurityOverviewWithForm);

export default connect(mapStateToProps)(SecurityOverviewWithOutRout);
